<template>
  <b-modal centered modal-class="activity_model"
           id="email-reports-logs" hide-footer
           hide-header>
    <div class="w-full h-full">
      <div @click="$bvModal.hide('email-reports-logs')" class="float-right w-6 h-6 flex justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z"
                fill="black"/>
        </svg>
      </div>
      <div class="w-full px-10 flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="32" viewBox="0 0 29 32" fill="none">
          <path d="M27.6853 13.5371L1.3147 2L11.2037 30.0188L14.5 16.8335L27.6853 13.5371Z" stroke="#2560D7"
                stroke-width="2.58635" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5">Report Email Activity</p>
        <div class="w-full py-3 px-4 bg-[#F4F6FA] rounded-lg mt-5"><p class="text-[14px] font-bold text-left">
          Activity</p></div>
        <div class="text !text-[14px] py-4 border-b w-full pl-4  text-left"
             v-if="getEmailReports && getEmailReports.length > 0"
             v-for="report in getEmailReports">
          A <span>{{ report.type }}</span> report is generated and sent on <span>{{ report.email }}</span> at
          {{ report.created_at.toString().slice(0, -3) }}
          <hr>
        </div>
        <div class="text !text-[14px] py-4 border-b w-full pl-4  text-left" v-if="getEmailReports.length === 0">
          No logs found for your reports.
        </div>
        <div class="pt-10 pb-11 flex gap-x-3 justify-between items-center">
          <Button
            id="login-button"
            type="button"
            class="border border-gray-500 hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="$bvModal.hide('email-reports-logs')"
          >
            <template v-slot:label>Close</template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import reports from '@/state/modules/reportsStore'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'

export default ({
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
  },
  data() {
    return {
      emailReports: [],
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      allBrands: [],
      allCampaign: []
    }
  },
  async created() {
    // await this.fetchEmailReports()
    // await this.fetchReports()
    // this.allBrands = await this.fetchBrandList({page: 1, isFetchAll: true})
    // this.allCampaign = await this.fetchCampaignsList({page: 1, isFetchAll: true})
  },
  computed: {
    ...mapGetters(['getReportsAdd', 'getReports', 'getReportsLoaders', 'getEmailReports'])
  },
  methods: {
    ...mapActions([
      'fetchReports',
      'changeArchive',
      'fetchEmailReports',
      'dispatchManualReports',
      'fetchBrandList',
      'fetchCampaignsList'
    ]),
  },
  watch: {
    async 'getReports.search'(value) {
      if (value.length === 0) {
        await this.fetchReports(1)
      } else if (value && value.length >= 3) {
        await this.fetchReports(1)
      }
    }
  }
})
</script>
<style lang="less">
.activity_model {
  .modal-dialog {
    max-width: 44.5rem !important;
  }

  .modal-content {
    border-radius: 16px !important;
    border-color: #F2F3F8 !important;
    padding: 16px !important;
  }

  .modal-body {
    padding: 0 !important;
  }
}

</style>
